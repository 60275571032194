import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomContabilizarGastosdeViaje
 * @author: Cristian Ciro
 * @version: jdesk_1.01.0002
 **/
class CustomContabilizarGastosdeViaje extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.consultarDatos             = this.consultarDatos.bind(this);
        this.successConsultarDatos      = this.successConsultarDatos.bind(this);

        this.gridOptionsDocumentos      = Object.assign({},this.gridOptions);
        this.gridOptionsDocumentos2      = Object.assign({},this.gridOptions);
        this.limpiarCampos              = this.limpiarCampos.bind(this);

        this.onSelectionChangedT                                    = this.onSelectionChangedT.bind(this);
        this.gridOptionsDocumentos["onSelectionChanged"]            = this.onSelectionChangedT;
        this.gridOptionsDocumentos2["onSelectionChanged"]            = this.onSelectionChangedT;

        this.formatterGeneral           = this.formatterGeneral.bind(this);
        this.numeroConsecutivo          = this.numeroConsecutivo.bind(this);
        this.successNumeroConsecutivo   = this.successNumeroConsecutivo.bind(this);
        this.habilitarBtnConsultar      = this.habilitarBtnConsultar.bind(this);
        this.limpiarConsecutivo         = this.limpiarConsecutivo.bind(this);
        this.autorizarTodo              = this.autorizarTodo.bind(this);
        this.successAutorizarTodo       = this.successAutorizarTodo.bind(this);
        this.successValor               = this.successValor.bind(this);
        this.setButtonTbRejilla         = this.setButtonTbRejilla.bind(this);
        this.setButtonTbRejillaImagenes = this.setButtonTbRejillaImagenes.bind(this);
        this.crearBotonTabla            = this.crearBotonTabla.bind(this);
        this.crearBotonTablaImagen      = this.crearBotonTablaImagen.bind(this);
        this.accionProcesar             = this.accionProcesar.bind(this);
        this.verImagen                  = this.verImagen.bind(this);
        this.getCuentaReintegro         = this.getCuentaReintegro.bind(this);
        this.successCuentaReintegro     = this.successCuentaReintegro.bind(this);
        this.enableBtnUpdateAll         = false;
        this.arrayTemp                  = [];

        this.successContabilidad        = this.successContabilidad.bind(this);
        this.contabilizarGastoViaje     = this.contabilizarGastoViaje.bind(this);
        this.successContabilizar        = this.successContabilizar.bind(this);
        this.valorTbChange              = this.valorTbChange.bind(this);
        this.centroCostoTbChange        = this.centroCostoTbChange.bind(this);

        this.successCentroCosto         = this.successCentroCosto.bind(this);

        this.contabilizado              = false;
        this.vendedorGastoId            = 0;
        this.setButtonClone             = this.setButtonClone.bind(this);
        this.clonarCampo                = this.clonarCampo.bind(this);
        this.successClonarCampo         = this.successClonarCampo.bind(this);
        this.setButtonEliminar          = this.setButtonEliminar.bind(this);
        this.eliminar                   = this.eliminar.bind(this);
        this.validarEliminar            = this.validarEliminar.bind(this);
        this.successEliminar            = this.successEliminar.bind(this);
    }

    onSelectionChangedT(){
        
    }

    initForm(){
        console.log('Formulario CustomContabilizarGastosdeViaje,  @version: jdesk_1.01.0002, @author:Cristian Ciro');

        // cont-contgastosdeviajes

        this.getCuentaReintegro();

        this.getField("consultar").setDisabled(true);
        this.getField("consultar").setClick(this.consultarDatos);
        this.getField("vendedor_nombre").setOnChange(()=>{this.limpiarCampos(); this.habilitarBtnConsultar();});
        
        //this.getField("vendedor_id").setOnChange(this.numeroConsecutivo);
        this.getField("vendedor").setOnChange(this.habilitarBtnConsultar);
        this.getField("numero_consecutivo").setOnChange(()=>{this.limpiarConsecutivo(); this.habilitarBtnConsultar();});

        this.getField("btnContabilizar").setClick(this.contabilizarGastoViaje);
    }

    getCuentaReintegro(){
        let datos = { 
            datos: {}
        };
        this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successCuentaReintegro,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successCuentaReintegro(data){
        if(data.estado_p === 200){
            this.getField("nombre_documento").setValue(data.data[0].nombre);
            this.getField("codigo_documento").setValue(data.data[0].codigo_documento);
        }
    }

    contabilizarGastoViaje(){
        this.gridOptionsDocumentos2.api.forEachNode(node => {
            this.gridOptionsDocumentos2['rowData'][node.rowIndex]['dse'] = node.selected
        });

        //terminarFactura

        //let arrayDataCheckBox = this.gridOptionsDocumentos.api.getSelectedRows();
        let datos = { 
            datos: {
                codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal
            },
            items: this.gridOptionsDocumentos2['rowData']
        };
        this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: 'contabilizar', operacion_tipo: 'consultar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'POST',
            body: datos,
            success: this.successContabilizar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: true
        });
    }

    successContabilizar(data){
        if(data.estado_p === 200){
            //this.getField("modal_contabilidad").handleClose();
            this.getField('confirmModalCustom').setTitleAndContent('Número Documento', 'El número para este documento es: ' + data.data.numero_documento);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); this.limpiarCampos(); this.habilitarBtnConsultar();});
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
                    
            //this.consultarDatos();
        }
    }

    autorizarTodo(){
        if (this.getField("vendedor").valid() && this.getField("vendedor_nombre").valid() &&
        this.getField('vendedor_id').getValue() !== '' && this.getField('numero_consecutivo').getValue() !== '') {
            let datos = { 
                datos: {
                    vendedor_id: this.getField("vendedor_id").getValue(),
                    numero_consecutivo: parseInt(this.getField("numero_consecutivo").getValue())
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: 'autorizar_todos', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successAutorizarTodo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successAutorizarTodo(data){
        if (data.estado_p === 200) {
            this.consultarDatos();
        }else if (data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al actualizar.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        this.enableBtnUpdateAll = false;
    }
    
    limpiarConsecutivo(){
        this.arrayTemp = [];
        this.enableBtnUpdateAll = false;
        this.gridOptionsDocumentos['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsDocumentos);
        this.getField('rejilla').toggle(false);
        this.getField("total_credito").setVisible(false);
        this.getField("total_debito").setVisible(false);
        this.getField("diferencia").setVisible(false);
        this.getField("btnContabilizar").setVisible(false);
    }
    
    habilitarBtnConsultar(){
        if (this.getField("vendedor").getValue() !== '' && this.getField("vendedor_nombre").getValue() !== '' &&
        this.getField("numero_consecutivo").getValue() !== '') {
            this.getField("consultar").setDisabled(false);
        }else{
            this.getField("consultar").setDisabled(true);
        }
    }
    
    numeroConsecutivo(){
        if(this.getField("vendedor").valid() && this.getField("vendedor_id").getValue() !== ''){
            this.datosAgregar={ 
                datos:{
                    codigo_vendedor: this.getField("vendedor").getValue()
                }
            };
   
            this.generalFormatPmv = { tipo_servicio: 'maes-vendedoresgastosconsecutivos', operacion: '1_5', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successNumeroConsecutivo,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successNumeroConsecutivo(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.numero_vendedor;
                dataOp['text'] = item.numero_vendedor;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            });
            this.getField('numero_consecutivo').setOptions(opciones);
            this.getField('numero_consecutivo').setValue('');
            this.getField('numero_consecutivo').setVisible(true);
            this.getField("codigo_documento").setVisible(true);
            this.getField("nombre_documento").setVisible(true);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No se encuentra consecutivos para el vendedor', 'error');
            this.getField('numero_consecutivo').setVisible(false);
            this.getField("codigo_documento").setVisible(false);
            this.getField("nombre_documento").setVisible(false);
            this.getField("consultar").setVisible(false);
        }
    }
    
    limpiarCampos(){
        if(this.getField("vendedor_nombre").getValue() !== ""){
            this.numeroConsecutivo();
            this.getField("vendedor_nombre").valid();
            this.getField("consultar").setDisabled(false);
            this.getField("consultar").setVisible(true);
            this.getField("codigo_documento").setVisible(true);
            this.getField("nombre_documento").setVisible(true);
            this.getField("numero_consecutivo").setVisible(true);
        }else {
            this.arrayTemp = [];
            this.enableBtnUpdateAll = false;
            this.gridOptionsDocumentos['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsDocumentos);
            this.getField('rejilla').toggle(false);
            this.getField("consultar").setDisabled(true);
            this.getField("codigo_documento").setVisible(false);
            this.getField("nombre_documento").setVisible(false);
            this.getField("numero_consecutivo").setVisible(false);
            this.getField("consultar").setVisible(false);
            this.getField("numero_consecutivo").setOptions([{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }]);
            this.getField("vendedor_id").setValue('');
            this.getField("numero_consecutivo").setError(false, '');
            this.getField("total_credito").setVisible(false);
            this.getField("total_debito").setVisible(false);
            this.getField("diferencia").setVisible(false);
            this.getField("btnContabilizar").setVisible(false);
        }
    }

    consultarDatos(){
        this.arrayTemp = [];
        if(this.getField("vendedor").valid() && this.getField("vendedor_nombre").valid()){
            this.getField("consultar").setDisabled(true);
            this.datosAgregar={ 
                datos:{
                    vendedor_id: this.getField("vendedor_id").getValue(),
                    codigo_vendedor: this.getField("vendedor").getValue(),
                    numero_consecutivo: parseInt(this.getField("numero_consecutivo").getValue())
                }
            };
   
            this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: '1_1', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successContabilidad,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successConsultarDatos(data){
        if(data.estado_p === 200){
                
            let configCell = new Map();
            this.gridOptionsDocumentos['rowData'] = data.data;
            configCell.set('fecha',{cellRenderer:function(props){return props.data.fecha.split("T")[0]},field:'fecha'});
            configCell.set('referencia',{cellRenderer:function(props){return props.data.codigo_documento},field:'referencia'});
            configCell.set('valor_gasto',{cellStyle: {textAlign:"right"},valueFormatter:(props)=>{return this.formatterGeneral(props,'valor');},field:'valor_gasto'});
            configCell.set('valor_autorizado',{cellStyle: {textAlign:"right"},valueFormatter:(props)=>{return this.formatterGeneral(props,'valor_autorizado');},field:'valor_autorizado'});
            configCell.set('accion', {cellRenderer:this.setButtonTbRejilla, headerName: 'Acción', sortable: false, filter: false, width:400});
            configCell.set('dse', {headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: false,checkboxSelection: true, sortable: false, filter: false, field: "dse", width: 43});
            //configCell.set('estado_contabilizar',{cellRenderer:function(props){return props.data.estado_contabilizar === 'C'?"CONTABILIZADO":"POR CONTABILIZAR"},field:'estado_contabilizar'});
            let createElement = this.createElementJaivana;
            configCell.set('estado_contabilizar', { cellRenderer: function (props) { 
                if (props.data.estado_contabilizar === 'C') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value","CONTABILIZADO");
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    return createElement(div);
                }else{
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value","POR CONTABILIZAR");
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    return createElement(div);
                }
            }, field: 'estado_contabilizar' });

            this.gridOptionsDocumentos["suppressRowClickSelection"] = true;
            this.gridOptionsDocumentos["rowSelection"] = "multiple";
            this.gridOptionsDocumentos["enableRangeSelection"] = true;
            this.gridOptionsDocumentos["enableCellChangeFlash"] = true;

            this.getField('rejilla').initData(this.gridOptionsDocumentos, configCell);
        }else{
            this.gridOptionsDocumentos['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsDocumentos);
            this.getField('rejilla').toggle(false);
        }
    }

    formatterGeneral(props,clave){
        let nStr = clave === 'valor' ? props.data.valor !== null?props.data.valor:'0' : props.data.valor_autorizado !== null? props.data.valor_autorizado: '0';
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1.$2');
        }
        if(x[1] !== undefined)
            return x1 + `,${x[1]}`;
        else
            return x1 + ',00';
    }
    
    successValor(data){
        if (data.estado_p === 200) {
        }else if (data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al actualizar.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    setButtonTbRejilla(props){
        let fragment = document.createDocumentFragment();
        let buttonVerImagen1 = this.crearBotonTabla(props.data, 'VER CONTABILIDAD');
        fragment.appendChild(buttonVerImagen1);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'VER CONTABILIDAD':
                button.setAttribute("id", `buttonImagen1_${data.id}`);
                button.setAttribute("class","buttonStyleImg");
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data) };

        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click} boton 
      */
    accionProcesar(data) {
        if(data.estado_contabilizar === "C"){
            this.contabilizado = true;
            this.getField("btnContabilizar").setDisabled(true);
        }else{
            this.contabilizado = false;
            this.getField("btnContabilizar").setDisabled(false);
        }

        this.vendedorGastoId = data.id;

        this.datosAgregar={ 
            datos:{
                value: data.id+'',
            }
        };

        this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: '1_1', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successContabilidad,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
        
    }

    successContabilidad(data){
        if(data.estado_p === 200){

            let valor_negativo = 0;
            let valor_positivo = 0;
            let diferencia = 0;

            data.data.forEach(item => {
                if(item.valor > 0){
                    valor_positivo += item.valor;
                }else{
                    valor_negativo += item.valor;
                }
            });
            diferencia = valor_negativo + valor_positivo;

            this.getField("total_credito").setVisible(true);
            this.getField("total_debito").setVisible(true);
            this.getField("diferencia").setVisible(true);
            this.getField("btnContabilizar").setVisible(true);

            this.getField("total_credito").setValue(valor_negativo);
            this.getField("total_debito").setValue(valor_positivo);
            this.getField("diferencia").setValue(diferencia);

            if(diferencia !== 0 || this.contabilizado){
                this.getField("btnContabilizar").setDisabled(true);
            }else{
                this.getField("btnContabilizar").setDisabled(false);
            }

            let configCell = new Map();
            this.gridOptionsDocumentos2['rowData'] = data.data;

            configCell.set(0,{cellRenderer:this.setButtonClone,width:100,sortable:false,filter:false,field:'clonar'});
            configCell.set('accion', {cellRenderer:this.setButtonTbRejillaImagenes, headerName: 'Acción', sortable: false, filter: false, width:100});
            configCell.set('dse', {headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: false,checkboxSelection: true, sortable: false, filter: false, field: "dse", width: 43});
            configCell.set('eliminar',{cellRenderer:this.setButtonEliminar,width:100,sortable:false,filter:false,field:'eliminar'});
            if(this.contabilizado){
                configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:(props)=>{return this.formatterGeneral(props,'valor');},field:'valor'});
            }else{
                configCell.set('valor',{cellStyle: {backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)'}, editable: true, onCellValueChanged: this.valorTbChange, valueFormatter:(props)=>{return this.formatterGeneral(props,'valor');},field:'valor'});
                configCell.set('centro_costo',{cellStyle: {backgroundColor: "rgb(238,238,238)", textAlign: "left", borderRadius: "5px", color: 'rgb(0, 86, 201)'}, editable: true, onCellValueChanged: this.centroCostoTbChange, valueFormatter:(props)=>{return props.data.centro_costo;} ,field:'centro_costo'});
            }
            this.gridOptionsDocumentos2["suppressRowClickSelection"] = true;
            this.gridOptionsDocumentos2["rowSelection"] = "multiple";
            this.gridOptionsDocumentos2["enableRangeSelection"] = true;
            this.gridOptionsDocumentos2["enableCellChangeFlash"] = true;

            this.getField('rejilla').initData(this.gridOptionsDocumentos2, configCell);
        }
    }

    setButtonTbRejillaImagenes(props){
        let fragment = document.createDocumentFragment();
        let imagenes = props.data.imagenes;

        if(props.data.imagenes.length === 0){
            let button = document.createElement('input');

            button.setAttribute("id", `buttonImagen1_${props.data.id}`);
            button.setAttribute("class","buttonStyleImgDisabled");

            button.setAttribute("value", "SIN IMAGEN");
            button.setAttribute("type", "button");
            fragment.appendChild(button);
        }else{
            for(let i=0; i<imagenes.length; i++){
                let buttonVerImagen1 = this.crearBotonTablaImagen(props.data, i);
    
                fragment.appendChild(buttonVerImagen1);
            }
        }
        
        return this.createElementJaivana(fragment);
    }

    crearBotonTablaImagen(data, pos) {
        let button = document.createElement('input');

        button.setAttribute("id", `buttonImagen1_${data.id}`);
        button.setAttribute("class","buttonStyleImg");

        button.onclick = () => { this.verImagen(data.imagenes[pos]) };

        button.setAttribute("value", "VER IMAGEN "+(pos+1));
        button.setAttribute("type", "button");
        return button;
    }

    verImagen(url_imagen){
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos=`?body={"datos":{"url":"${url_imagen}"},"generales":{"tipo_servicio":"maes-documentosporvendedor","operacion":"url_imagen","operacion_tipo":"consulta"}}`;
        fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
            })
        })
        .then(response => {
            if(response.status!==200){                    
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        }).then(stream => new Response(stream))
            .then(response => {
                return response.blob()
            })
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => console.error(err));
    }

    checkedValue(props){
        console.log("Entra = ",props);
    }

    valorTbChange(props){
        let seguir = this.getField('rejilla').cellValid(props.oldValue, props.newValue,"^-?(\\d{1,14})(\\.\\d{1,2})?$");
        if (isNaN(props.data.valor) || seguir===false) {
            if(props.newValue > 9999999999999){
                this.getField('confirmModalCustom').setTitleAndContent('Longitud Incorrecta', `El valor debe estar entre las longitudes (1,9999999999999)`);
                this.getField('confirmModalCustom').setClickDialog(()=> {this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna valor autorizado, no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }
            this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].valor = props.oldValue;
            let newData = {
                estado_p: 200,
                data: this.gridOptionsDocumentos2["rowData"]
            }
            this.successContabilidad(newData);
        }else if(seguir){   

            if(this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].concepto === 'gasto'){
                let valor_g = props.newValue;

                if(valor_g < 0){
                    valor_g = valor_g * -1;
                }

                this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].valor = valor_g;

                let valor_n = 0.0;
                this.gridOptionsDocumentos2["rowData"].forEach((item) => {
                    if(item.concepto === "gasto"){
                        valor_n += item.valor;
                    }
                    if(item.concepto === "gasto_n"){
                        item.valor = valor_n * -1;
                    }
                });

                let newData = {
                    estado_p: 200,
                    data: this.gridOptionsDocumentos2["rowData"]
                }
                this.successContabilidad(newData);

                let datos = { datos: {
                    vendedor_id: props.data.id,
                    value: valor_g + ''
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: '6_1', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successValor,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

            }else if(this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].concepto === 'gasto_n'){
                this.getField('confirmModalCustom').setTitleAndContent('Alerta', `No se permite modificar este valor.`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            
                this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].valor = props.oldValue;
                let newData = {
                    estado_p: 200,
                    data: this.gridOptionsDocumentos2["rowData"]
                }
                this.successContabilidad(newData);
            }else{
                let valor_g = props.newValue;

                if(valor_g < 0){
                    valor_g = valor_g * -1;
                }
                this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].valor = valor_g * -1;

                let valor_n = 0;
                this.gridOptionsDocumentos2["rowData"].forEach((item) => {
                    if(item.concepto === "gasto"){
                        valor_n = item.valor;
                    }
                    if(item.concepto === "concepto"){
                        valor_n += item.valor;
                    }
                    if(item.concepto === "gasto_n"){
                        item.valor = valor_n * -1;
                    }
                });

                let newData = {
                    estado_p: 200,
                    data: this.gridOptionsDocumentos2["rowData"]
                }
                this.successContabilidad(newData);

                let datos = { datos: {
                    vendedor_id: props.data.id,
                    value: valor_g + ''
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-contgastosdeviajes', operacion: '6_2', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successValor,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
            /*if (Number(props.newValue) <= Number(props.data.valor)) {
                let valor_actual = props.newValue;
                let datos = { datos: {
                    vendedor_id: Number(props.data.id),
                    valor_autorizado: Number(valor_actual)
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: 'modificarvalorautorizado', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successValorAutorizado,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Error', `El número ingresado, debe ser inferior al número de la columna valor.`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.consultarDatos(); this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }*/
            
        }
    }

    centroCostoTbChange(props){
        let seguir = this.getField('rejilla_ver_contabilidad').cellValid(props.oldValue, props.newValue,"^[0-9]+$", 4);
        if (seguir===false) {
            this.getField('confirmModalCustom').setTitleAndContent('Longitud Incorrecta', `El valor debe estar entre las longitudes (1,4)`);
            this.getField('confirmModalCustom').setClickDialog(()=> {
                this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].centro_costo = props.oldValue; 
                let newData = {
                    estado_p: 200,
                    data: this.gridOptionsDocumentos2["rowData"]
                }
                this.successContabilidad(newData);
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
            this.getField('confirmModalCustom').toggle(true);
        }else if(seguir){       
            if (props.newValue !== props.oldValue) {
                let datos = { datos: {
                    value: props.newValue
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-centroscosto', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success:(data) => this.successCentroCosto(data,props),
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
        }
    }

    successCentroCosto(data, props){
        if(data.estado_p === 200){

            this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].centro_costo = data.data[0].codigo_centro_costo;
            this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].nombre_centro_costo = data.data[0].nombre;
             
            let newData = {
                estado_p: 200,
                data: this.gridOptionsDocumentos2["rowData"]
            }
            this.successContabilidad(newData);
        }else{
            this.gridOptionsDocumentos2["rowData"][props.node.rowIndex].centro_costo = props.oldValue;

            let newData = {
                estado_p: 200,
                data: this.gridOptionsDocumentos2["rowData"]
            }
            this.successContabilidad(newData);
        }
    }

    currencyFormatterGeneral(number){
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
                decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number){
        return Number(number).toString().split('.')[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    setButtonClone(data){
        let props=data;
        let id = props.data.id;
        //let fecha = props.data.fecha_documento.split('-')[0];
        let fragment = document.createDocumentFragment();
        
        if (props.data.id === 1){
            let button = document.createElement('input');
            button.setAttribute("id", `button_clonar_${props.data.id}`);
            button.setAttribute("class","buttonStyleImgDisabled");

            button.setAttribute("value", "SIN CLONAR");
            button.setAttribute("type", "button");
            fragment.appendChild(button);
        }else{
            let button2 = document.createElement('input');
            

            button2.onclick = ()=>this.clonarCampo(props);
            button2.setAttribute("id",'button_clonar_'+id);
            button2.setAttribute("class","buttonStyle");
            button2.setAttribute("type","button");
            button2.setAttribute("value","CLONAR");

            fragment.appendChild(button2);
        }

        return this.createElementJaivana(fragment);

    }

    clonarCampo(data){
        let datos={ datos: { 
            vendedores_tipos_documentos_id:data.data.vendedores_tipos_documentos_id,
            tercero_id:data.data.tercero_id, 
            numero_documento:data.data.numero_documento, 
            valor:data.data.valor, 
            detalle:data.data.detalle,
            usuario_id:data.data.usuarios_id,
            consecutivo:data.data.consecutivo, 
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-vendedoresgastos', operacion: 'clonargasto', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successClonarCampo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successClonarCampo(data){
        if(data.estado_p === 200){
            this.consultarDatos();
        }else{
            this.alertGeneral.toggle(true, 'Error al clonar registro.', 'error');
        }
    }

    setButtonEliminar(props){
        let id = props.data.id;
        //let fecha = props.data.fecha_documento.split('-')[0];
        let fragment = document.createDocumentFragment();
        
        if (props.data.id === 1){
            let button = document.createElement('input');
            button.setAttribute("id", `button_eliminar_${props.data.id}`);
            button.setAttribute("class","buttonStyleImgDisabled");

            button.setAttribute("value", "SIN ELIMINAR");
            button.setAttribute("type", "button");
            fragment.appendChild(button);
        }else{
            let button2 = document.createElement('input');
            

            button2.onclick = ()=>this.eliminar(id);
            button2.setAttribute("id",'button_eliminar_'+id);
            button2.setAttribute("class","buttonStyle");
            button2.setAttribute("type","button");
            button2.setAttribute("value","ELIMINAR");

            fragment.appendChild(button2);
        }

        return this.createElementJaivana(fragment);
    }

    eliminar(id){
        this.idEliminar = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.validarEliminar);
        this.getField('confirmModalCustom').toggle(true);
    }

    
    validarEliminar(){
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id:this.idEliminar
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-vendedoresgastos', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.consultarDatos();
        }else{
            this.alertGeneral.toggle(true, 'Error al eliminar registro.', 'error');
        }

    }



}
FormJaivana.addController("cont-contgastosdeviajes",CustomContabilizarGastosdeViaje);
export default CustomContabilizarGastosdeViaje